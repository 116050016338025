import { useCallback } from 'react'

import { useMutation, useQuery } from 'react-query'
import { AxiosError } from 'axios'

import FactoringAgreementScreen from '../screens/FactoringAgreement'

import usePage from '../hooks/usePage'

import { usePatient } from '../providers/PatientProvider'
import { useFactoringAgreement } from '../providers/FactoringAgreementProvider'
import { useAnswers } from '../providers/AnswersProvider'
import api from '../services/api'
import sentryService from 'services/sentry'
import ServerError from '../@types/ServerError'
import { getFactoringAgreementUrl } from '../services/api/ana'

const FactoringAgreementPage: React.FC = () => {
  const page = usePage()

  const answers = useAnswers()
  const patient = usePatient()
  const factoringAgreement = useFactoringAgreement()

  const params = {
    patientReferenceId: patient?.data?.referenceId || '',
    language: answers.language?.toUpperCase() || 'DE',
    code: answers.code || '',
  }

  useQuery(['patient-factoring', params], () => api.ana.getPatientFactoringAgreement(params), {
    retry: (count: number, error: AxiosError) => {
      if (error.response?.status === 409) {
        return false
      }
      return count <= 3
    },
    enabled: !factoringAgreement.pdfLink,
    onSuccess: (data) => {
      const pdfLink = answers.code
        ? getFactoringAgreementUrl(params.patientReferenceId, answers.code)
        : data.documentKey || ''

      factoringAgreement.setPdfLink(pdfLink)
    },
    onError: (error: AxiosError<ServerError>) => {
      if (error.response?.status === 404) {
        sentryService.captureFactoringAgreementNotFoundError({
          error,
          code: answers.code,
          clinic: patient?.data?.clinic,
        })
      }

      if (error.response?.status === 404 || error.response?.status === 409) {
        // Skip the factoring page if the document could not be found (status 404) or was already signed (status 409)
        return page.onSubmit({})
      }

      sentryService.captureGetFactoringAgreementError({
        error,
        code: answers.code,
        clinic: patient?.data?.clinic,
      })
    },
  })

  const { mutateAsync: signDocument, ...signDocumentStatus } = useMutation(api.ana.postPatientFactoringSign, {
    onSuccess: () => {
      page.onSubmit({})
    },
    onError: (error: AxiosError) => {
      if (error.response?.status === 409) {
        page.onSubmit({})

        return
      }

      sentryService.captureSignFactoringError({
        error,
        code: answers.code,
        clinic: patient?.data?.clinic,
        extra: {
          anamnesisState: patient?.data?.anamnesisState,
        },
      })
    },
  })

  const handleSignatureSubmit = useCallback(
    (signature: string) => {
      if (patient?.data?.referenceId) {
        signDocument({
          signatureEncoded: signature.replace('data:image/png;base64,', ''),
          patientReferenceId: patient?.data?.referenceId,
          language: answers.language?.toUpperCase() || 'DE',
          code: answers.code || '',
        })
      } else {
        throw new Error("Can't submit factoring agreement without patient.referenceId")
      }
    },
    [answers.language, answers.code, patient, signDocument]
  )

  return (
    <FactoringAgreementScreen
      {...page}
      onSignatureSubmit={handleSignatureSubmit}
      factoringAgreementPdfLink={factoringAgreement.pdfLink}
      signatureLoading={signDocumentStatus.isLoading}
    />
  )
}

export default FactoringAgreementPage
