import axios from 'axios'

import Patient from '../../@types/Patient'
import PatientFactoringAgreementResult from '../../@types/PatientFactoringAgreementResult'
import { CommitInfo } from '../../@types/CommitInfo'
import { addAuthInterceptors } from '@dentalux/security'
import { FormWithComments } from '../../@types/FormWithComments'
import Revision from '../../@types/Revision'
import { Form, Optins } from '@dentalux/ui-library-core'
import { Anamnesis } from '../../@types/Anamnesis'

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

const httpWithCredentials = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
})

// @ts-ignore
addAuthInterceptors(httpWithCredentials)

const httpBase = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

export const getFactoringAgreementUrl = (patientReferenceId: string, code: string): string => {
  return `${
    process.env.REACT_APP_API_URL
  }/patient/${patientReferenceId}/factoring-agreement/download?code=${encodeURIComponent(code)}`
}

export const getPatient = async (anamnesisCode: string): Promise<Patient> => {
  const { data } = await http.get('/anamnesis/patient', {
    params: { anamnesisCode, includeOptins: 'true' },
  })

  return data
}

export const getReferral = async (anamnesisCode: string): Promise<boolean> => {
  const { data } = await http.get(`public/codes/${anamnesisCode}/anamnesis/referrals`)

  return data
}

export const getOptins = async (anamnesisReferenceId: string): Promise<Optins> => {
  const { data } = await httpWithCredentials.get(`internal/anamnesis/${anamnesisReferenceId}/patients/opts`)

  return data
}

export const getLatestVersion = async (): Promise<CommitInfo> => {
  if (process.env.NODE_ENV === 'development') {
    return { created: 'now', commitId: '12345', force: false }
  }

  const { data } = await httpBase.get(process.env.REACT_APP_LATEST_COMMIT ?? 'latestCommit.json')

  return data
}

export type PostAnamnesisResponse = {
  factoringAgreementUrl: string
  referenceId: string
  healthAssistantSuccessActionInfoResult: {
    linkToHealthAssistant: string
    patientHasHealthAssistant: boolean
  }
}

export type UpdateAnamnesisInput = {
  anamnesisReferenceId: string
  form: Partial<FormWithComments>
}

export const updateAnamnesis = async ({ anamnesisReferenceId, form }: UpdateAnamnesisInput): Promise<Anamnesis> => {
  const { data } = await httpWithCredentials.put(`/anamnesis/${anamnesisReferenceId}`, form)

  return data
}

export const postAnamnesis = async (form: Partial<Form>): Promise<PostAnamnesisResponse> => {
  const { data } = await http.post('/anamnesis', form, {})

  return data
}

export type GetPatientFactoringAgreementInput = {
  patientReferenceId: string
  language: string
  code: string
}

export const getPatientFactoringAgreement = async ({
  patientReferenceId,
  ...params
}: GetPatientFactoringAgreementInput): Promise<PatientFactoringAgreementResult> => {
  const { data } = await http.get(`/patient/${patientReferenceId}/factoring-agreement`, { params })

  return data
}

export const downloadPatientFactoringAgreementPdf = async ({
  patientReferenceId,
  ...params
}: GetPatientFactoringAgreementInput): Promise<PatientFactoringAgreementResult> => {
  const { data } = await http.get(`/patient/${patientReferenceId}/factoring-agreement`, { params })

  return data
}

export const postPatientFactoringSign = ({
  patientReferenceId,
  ...body
}: {
  patientReferenceId: string
  language: string
  signatureEncoded: string
  code: string
}): Promise<void> => {
  return http.post(`/patient/${patientReferenceId}/factoring-agreement/sign`, body)
}

export interface PostOptins {
  code: string
  marketingCommunicationConsentAccepted?: boolean
  confidentialityAgreementAccepted?: boolean
}

export const postOptins = ({ code, ...body }: PostOptins): Promise<void> => {
  return http.post(`/public/codes/${code}/patients/opts`, body)
}

export const getVersions = async (anamnesisReferenceId: string): Promise<Revision[]> => {
  const { data } = await httpWithCredentials.get(`/anamnesis/${anamnesisReferenceId}/revisions`)

  return data
}

export const getAnamnesisByVersion = async (
  anamnesisReferenceId: string,
  revision: string | number
): Promise<Anamnesis> => {
  const { data } = await httpWithCredentials.get(`/anamnesis/${anamnesisReferenceId}/revisions/${revision}`)

  return data
}
